import * as React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
export default function Variants() {
  return (
    <Stack
      spacing={1}
      sx={{ minHeight: '100vh', margin: 'auto', marginTop: '13rem' }}
      alignItems={'center'}
    >
      <Skeleton variant="rounded" width={'20%'} height={40} animation="wave" />
      <br />

      <Skeleton
        variant="rounded"
        width={
          typeof window === 'object' && window.innerWidth > 780 ? '70%' : '90%'
        }
        height={
          typeof window === 'object' && window.innerWidth > 780 ? 100 : 60
        }
        animation="wave"
      />
      <br />
      <Skeleton
        variant="rounded"
        width={
          typeof window === 'object' && window.innerWidth > 780 ? '50%' : '60%'
        }
        height={typeof window === 'object' && window.innerWidth > 780 ? 70 : 50}
        animation="wave"
      />
      <br />

      <Skeleton variant="rounded" width={'30%'} height={40} animation="wave" />
    </Stack>
  )
}
