/* eslint-disable @next/next/next-script-for-ga */
import * as React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { CookieValueTypes, getCookie } from 'cookies-next'

interface HeadTagProps {
  title?: string | null | undefined
  description?: string | null | undefined
  image?: string
}

const HeadTag: React.FC<HeadTagProps> = ({ title, description }) => {
  const [lang, setLang] = React.useState<CookieValueTypes>()

  // const cookieStore = cookies()
  //   if (typeof cookieStore === 'object')
  //   {

  //    const cookie_lang = cookieStore?.get('lang')
  //    setLang(cookie_lang)
  //    console.log(cookie_lang)

  //  }

  const [jsonLD] = React.useState({
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'WebSite',
        '@id': 'https://ditchthelabel.org/#website',
        url: 'https://ditchthelabel.org',
        name: title ? title : 'Ditch The Label',
        description: description
          ? description
          : 'Ditch the Label, a mental health platform to help people in providing guidance in mental health related problems.',
        potentialAction: [
          {
            '@type': 'SearchAction',
            target: 'https://ditchthelabel.org/search',
          },
        ],
        inLanguage: 'en-US',
      },
      {
        '@type': 'ImageObject',
        '@id': 'https://ditchthelabel.org/#primaryimage',
        inLanguage: 'en-US',
        url: '',
        width: 877,
        height: 319,
        caption: 'Ditch The Label Logo',
      },
      {
        '@type': 'WebPage',
        '@id': 'https://ditchthelabel.org/#webpage',
        url: 'https://ditchthelabel.org/',
        name: 'MrBeast Burger American Smash Burgers & Fried Chicken Sandwiches',
        isPartOf: { '@id': 'https://ditchthelabel.org/#website' },
        primaryImageOfPage: {
          '@id': 'https://ditchthelabel.org/#primaryimage',
        },
        datePublished: '2020-11-23T22:17:43+00:00',
        dateModified: '2021-04-27T16:23:57+00:00',
        description:
          'Ditch the Label, a mental health platform to help people in providing guidance in mental health related problems.',
        inLanguage: 'en-US',
        potentialAction: [
          {
            '@type': 'ReadAction',
            target: ['https://ditchthelabel.org/'],
          },
        ],
      },
    ],
  })

  const router = useRouter()

  React.useEffect(() => {
    // if (typeof cookies() === 'object')

    // const cookieStore = cookies()
    const cookie_lang = getCookie('lang')
    setLang(cookie_lang)
  }, [])

  if (process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'es-MX') {
    return (
      <Head>
        <meta charSet="UTF-8" />
        <link
          rel="shortcut icon"
          href={lang === 'es-MX' ? '/favicon-qle.ico' : '/favicon.ico'}
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','G-RJ0WEGMF8H');`,
          }}
        ></script>

        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=G-RJ0WEGMF8H"
            height="0"
            width="0"
          ></iframe>
        </noscript>

        <meta name="robots" content="max-image-preview:large" />
        <title>{title ? title : 'QUITATE LAS ETIQUETAS'}</title>
        {/* favicon */}
        <link rel="icon" type="image/x-icon" href="/favicon.ico"></link>
        <meta
          name="description"
          content={
            description
              ? description
              : 'Quitate las etiquetas, una plataforma de salud mental para ayudar a las personas a brindar orientación sobre problemas relacionados con la salud mental.'
          }
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://qle.mx/" />
        {/* open graph description */}
        <meta property="og:locale" content="en_MX" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={title ? title : 'QUITATE LAS ETIQUETAS'}
        />
        <meta
          property="og:description"
          content={
            description
              ? description
              : 'Quitate las etiquetas, una plataforma de salud mental para ayudar a las personas a brindar orientación sobre problemas relacionados con la salud mental.'
          }
        />
        <meta property="og:url" content={`https://qle.mx${router.route}`} />
        <meta property="og:site_name" content="QUITATE LAS ETIQUETAS" />
        <meta
          property="article:modified_time"
          content="2023-05-21T13:23:57+00:00"
        />
        <meta property="og:image" content="/qle-og.png" />
        <meta property="og:image:width" content="768" />
        <meta property="og:image:height" content="400" />
        {/* social media */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Quítate Las Etiquetas" />
        {/* <meta
          name="twitter:description"
          content="Ditch the Label, a mental health platform to help people in providing guidance in mental health related problems."
        /> */}
        <meta name="twitter:image" content="/qle-og.png" />
        <meta name="facebook:card" content="summary_large_image" />
        <meta name="twitter:title" content="Quítate Las Etiquetas" />
        <meta
          name="google-site-verification"
          content="OQxo9VTQcQm3Otq56uV7AIJPqH3lPcYAWSHr0KCCN20"
        />
        <meta
          name="twitter:description"
          content={
            description
              ? description
              : 'Quitate las etiquetas, una plataforma de salud mental para ayudar a las personas a brindar orientación sobre problemas relacionados con la salud mental.'
          }
        />
        {/* JSON LD */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLD) }}
        />
        <meta name="smartbanner:title" content="QUITATE LAS ETIQUETAS" />
        <meta
          name="smartbanner:author"
          content={
            description
              ? description
              : 'Quitate las etiquetas, una plataforma de salud mental para ayudar a las personas a brindar orientación sobre problemas relacionados con la salud mental.'
          }
        />
        <meta name="smartbanner:button" content="VIEW" />
        <meta name="smartbanner:enabled-platforms" content="android,ios" />
        <meta name="smartbanner:close-label" content="Close" />
        <meta name="smartbanner:api" content="true" />
        {/* apple & google meta tags */}
        <meta name="smartbanner:icon-apple" content="/qle-og.png" />
        <meta name="smartbanner:button-url-apple" content="/qle-og.png" />
        <meta name="apple-itunes-app" content="/qle-og.png" />
        <meta name="smartbanner:icon-google" content="/qle-og.png" />
        <meta name="smartbanner:button-url-google" content="/qle-og.png" />
        <link rel="icon" href="" sizes="32x32" />
        <link rel="icon" href="" sizes="192x192" />
        <link rel="apple-touch-icon" href="/qle-og.png" />
        <meta name="msapplication-TileImage" content="/qle-og.png" />
      </Head>
    )
  }

  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, viewport-fit=cover"
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-TCFSJ46');`,
        }}
      ></script>

      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-TCFSJ46"
          height="0"
          width="0"
        ></iframe>
      </noscript>

      <meta name="robots" content="max-image-preview:large" />
      <title>{title ? title : process.env.NEXT_PUBLIC_META_TITLE}</title>
      {/* favicon */}
      <link
        rel="icon"
        type="image/x-icon"
        href={process.env.NEXT_PUBLIC_FAVICON}
      ></link>
      <meta
        name="description"
        content={
          description
            ? description
            : 'Ditch the Label, a mental health platform to help people in providing guidance in mental health related problems.'
        }
      />
      <meta
        name="robots"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <link rel="canonical" href="https://ditchthelabel.org/" />
      {/* open graph description */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={title ? title : 'Ditch the Label, mental health platform'}
      />
      <meta
        property="og:description"
        content={
          description
            ? description
            : 'For over 10 years, we’ve been on a mission to help young people overcome the issues that affect them the most. Whether that’s bullying, mental health, relationships, identity or the tough stuff in-between, we’re here. We provide digital safe spaces, direct support, education, campaigns and world-class research.'
        }
      />
      <meta
        property="og:url"
        content={`https://ditchthelabel.org${router.route}`}
      />
      <meta property="og:site_name" content="Ditch The Label" />
      <meta
        property="article:modified_time"
        content="2023-05-21T13:23:57+00:00"
      />
      <meta property="og:image" content="/dtl-image.png" />
      <meta property="og:image:width" content="768" />
      <meta property="og:image:height" content="400" />
      {/* social media */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Ditch the Label" />
      <meta
        name="twitter:description"
        content="Ditch the Label, a mental health platform to help people in providing guidance in mental health related problems."
      />
      <meta name="twitter:image" content="/dtl-image.png" />
      <meta name="facebook:card" content="summary_large_image" />
      <meta name="twitter:title" content="Ditch the Label" />
      <meta
        name="twitter:description"
        content={
          description
            ? description
            : 'Ditch the Label, a mental health platform to help people in providing guidance in mental health related problems.'
        }
      />
      {/* JSON LD */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLD) }}
      />
      <meta name="smartbanner:title" content="Ditch The Label" />
      <meta
        name="smartbanner:author"
        content={
          description
            ? description
            : 'Ditch the Label, a mental health platform to help people in providing guidance in mental health related problems.'
        }
      />
      <meta name="smartbanner:button" content="VIEW" />
      <meta name="smartbanner:enabled-platforms" content="android,ios" />
      <meta name="smartbanner:close-label" content="Close" />
      <meta name="smartbanner:api" content="true" />
      {/* apple & google meta tags */}
      <meta name="smartbanner:icon-apple" content="/dtl-image.png" />
      <meta name="smartbanner:button-url-apple" content="/dtl-image.png" />
      <meta name="apple-itunes-app" content="/dtl-image.png" />
      <meta name="smartbanner:icon-google" content="/dtl-image.png" />
      <meta name="smartbanner:button-url-google" content="/dtl-image.png" />
      <link rel="icon" href="" sizes="32x32" />
      <link rel="icon" href="" sizes="192x192" />
      <link rel="apple-touch-icon" href="/dtl-image.png" />
      <meta name="msapplication-TileImage" content="/dtl-image.png" />
    </Head>
  )
}

export default HeadTag
